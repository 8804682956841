import css from "@emotion/css";
import dimensions from "styles/dimensions";
import colors from "styles/colors";

const typeStyles = css`
  h1 {
    font-size: 4.2rem;
    line-height: 5rem;
    font-weight: 700;
    color: ${colors.dark};

    @media (max-width: ${dimensions.maxwidthTablet}px) {
      font-size: 2.8rem;
      line-height: 3rem;
    }
  }

  h2 {
    margin-top: 4rem;
    margin-bottom: 1rem;
    font-size: 2.8rem;
    line-height: 3rem;
    color: ${colors.dark};
    @media (max-width: ${dimensions.maxwidthTablet}px) {
      font-size: 2.4rem;
      line-height: 2.6rem;
    }
  }

  h3 {
    font-weight: 800;
    font-size: 2.4rem;
    color: ${colors.grey};
    line-height: 2.6rem;
    margin-bottom: -0.5rem;
    @media (max-width: ${dimensions.maxwidthTablet}px) {
      font-size: 2rem;
      line-height: 2.2rem;
    }
  }

  h4 {
    font-size: 2rem;
    line-height: 2rem;
    margin-top: 0.5rem;
    margin-bottom: -1rem;
    color: ${colors.grey};
    @media (max-width: ${dimensions.maxwidthTablet}px) {
      font-size: 1.8rem;
      line-height: 1.8rem;
    }
  }

  h5 {
    margin-bottom: 1.45rem;
    font-weight: 500;
    line-height: 2rem;
    font-size: 0.95rem;
  }

  h6 {
    font-size: 0.9rem;
    font-weight: 500;
    margin: 0;
  }

  p {
    line-height: 1.7;
    color: #4a4a4a;
  }

  a {
    color: ${colors.accent};
    &:hover {
      color: ${colors.dark};
      cursor: pointer;
    }
  }
`;

export default typeStyles;
