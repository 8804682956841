//Dimensions for device media queries and layout padding
const dimensions = {
  //pixels
  maxwidthDesktop: 1280,
  maxwidthTablet: 1024,
  maxwidthMobile: 600,
  maxwidthPhone: 450,

  //ems
  paddingHorizontalDesktop: 4,
  paddingHorizontalTablet: 2.5,
  paddingHorizontalMobile: 2,
  paddingHorizontalPhone: 1
};

export default dimensions;
