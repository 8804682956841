import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import styled from "@emotion/styled";
import colors from "styles/colors";
import Logo from "components/_ui/Logo";
import dimensions from "styles/dimensions";

const FooterContainer = styled("div")`
  border-top: 0.2rem solid ${colors.grey};
  margin-top: 8rem;
  padding: 2rem 4rem 6rem;
  img,
  svg {
    max-width: 14.8rem;
  }
  display: grid;
  grid-template-columns: 40% 30% 30%;
  @media (max-width: ${dimensions.maxwidthTablet}px) {
    padding: 2rem 0rem 6rem;
  }
  @media (max-width: ${dimensions.maxwidthMobile}px) {
    text-align: center;
    grid-template-columns: 1fr;
  }
  h3 {
    padding-top: 0;
    margin-top: 0.3em;
    margin-bottom: 1.7rem;
  }
  p {
    line-height: 1.3;
  }
`;

const FooterLinks = styled("div")`
  text-align: center;
  font-size: 1.4rem;
  color: ${colors.grey};
  a {
    color: ${colors.grey};
    &:visited {
      color: ${colors.grey};
    }
    &:hover {
      color: ${colors.accent};
    }
  }
`;

const PhoneMail = styled("div")`
  display: grid;
  grid-template-columns: 65px minmax(160px, 100%);
  @media (max-width: ${dimensions.maxwidthMobile}px) {
    max-width: 220px;
    margin: 0 auto 3rem;
  }
`;

const Footer = ({ data }) => (
  <FooterContainer>
    <div>
      <Logo />
      <p>
        &copy; Copyright 2020 Digital Rain
        <br />
        All rights reserved.
      </p>
    </div>
    <div>
      <h3>Contact us</h3>
      <p>
        Friedrichstraße 123
        <br />
        10117 Berlin
        <br />
        Germany
        <br />
      </p>
      <PhoneMail>
        <span>phone</span>
        <span>+49 30 20 99 58 64</span>
        <span>mail</span>
        <span>info@digitalrain.de</span>
      </PhoneMail>
    </div>
    <FooterLinks>
      <div>
        <a href="https://www.digitalrain.de/#impressum">Imprint</a>
        &nbsp;&nbsp;&nbsp;
        <a href="https://www.digitalrain.de/#datenschutz">Privacy</a>
      </div>
    </FooterLinks>
  </FooterContainer>
);

export default Footer;
