import React from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import colors from "styles/colors";
import dimensions from "styles/dimensions";
import Logo from "components/_ui/Logo";

const HeaderContainer = styled("div")`
  padding-top: 0;
  padding-bottom: 1rem;
  padding-left: 4rem;
  padding-right: 4rem;
  // position: absolute;
  // z-index: 1;
  // width: 100%;
  @media (max-width: ${dimensions.maxwidthPhone}px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

const HeaderContent = styled("div")`
  display: flex;
  justify-content: space-between;
  .logo-wrapper {
    display: flex;
    align-items: flex-end;
  }
  @media (max-width: ${dimensions.maxwidthPhone}px) {
    padding-top: 20px;
    justify-content: center;
    flex-wrap: wrap;
  }
`;

const HeaderLinks = styled("div")`
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 4rem;
  justify-content: flex-end;
  width: 100%;
  max-width: 200px;

  @media (max-width: ${dimensions.maxwidthTablet}px) {
    grid-gap: 5.5rem;
  }

  @media (max-width: ${dimensions.maxwidthMobile}px) {
    grid-gap: 2.5rem;
  }

  a {
    color: currentColor;
    text-decoration: none;
    border-bottom: 3px solid transparent;
    font-weight: 600;
    font-size: 1.6rem;
    height: 100%;
    padding-top: 4rem;
    display: block;
    position: relative;

    &:after {
      position: absolute;
      content: "";
      bottom: 0;
      width: 18px;
      height: 3px;
      background: transparent;
      bottom: -3px;
      right: 50%;
      margin-right: -9px;
      transition: 100ms ease-in-out background;
    }

    &:hover {
      &:after {
        background: ${colors.accent};
        transition: 100ms ease-in-out background;
      }
    }

    &.Link--is-active {
      &:after {
        background: ${colors.accent};
        transition: 100ms ease-in-out background;
      }
    }
  }
  @media (max-width: ${dimensions.maxwidthMobile}px) {
    grid-gap: 2.5rem;
    max-width: 100%;
    justify-content: center;
    a {
      padding-top: 2rem;
    }
  }
`;

const Header = () => (
  <HeaderContainer>
    <HeaderContent>
      <Link to="/" className="logo-wrapper">
        <Logo />
      </Link>
      <HeaderLinks>
        <a href="https://digitalrain.de">Home</a>
        <Link activeClassName="Link--is-active" to="/">
          Blog
        </Link>
        <a href="https://jobs.digitalrain.de">Jobs</a>
      </HeaderLinks>
    </HeaderContent>
  </HeaderContainer>
);

export default Header;
